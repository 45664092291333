<template>
  <div>
    <div
      class="modal fade lg"
      id="withdrawalModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Take Action
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card shadow">
              <div class="card-body">
                <p class="mb-1 h5">
                  User Id:
                  <span class="badge font-weight-normal">
                    {{ currentWithdrawal.user }}
                  </span>
                </p>

                <p class="mb-1 h5">
                  Status:
                  <span class="badge font-weight-normal">
                    {{ currentWithdrawal.status }}</span
                  >
                </p>
                <p class="mb-1 h5">
                  Amount:
                  <span class="badge font-weight-normal"
                    >&#8358;{{ currentWithdrawal.amount | formartNumber }}</span
                  >
                </p>

                <p class="mb-1">
                  <span class="h5">
                    Date:
                  </span>
                  <span class="badge font-weight-normal"
                    >{{ currentWithdrawal.createdAt | formartDate }}
                  </span>
                </p>
                <div
                  v-if="currentWithdrawal.status == 'PENDING'"
                  class="container mt-3"
                >
                  <div class="row justify-content-around">
                    <div>
                      <button
                        :disabled="loading"
                        v-if="!cancelLoading"
                        @click="Cancel"
                        class="btn btn-outline-danger"
                      >
                        Cancel
                      </button>
                      <button
                        v-else
                        class="btn btn-outline-danger"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </div>
                    <div>
                      <button
                        :disabled="loading"
                        v-if="!redeemLoading"
                        @click="Approve"
                        class="btn btn-outline-success"
                      >
                        Approve
                      </button>
                      <button
                        v-else
                        class="btn btn-outline-success"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <p>This has been marked: {{ currentWithdrawal.status }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
import WithdrawalService from "@/services/withdrawal.service";

export default {
  name: "ActionModal",
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
  data() {
    return {
      loading: false,
      redeemLoading: false,
      cancelLoading: false,
    };
  },
  methods: {
    async Approve() {
      this.redeemLoading = true;
      this.loading = true;
      try {
        const response = await WithdrawalService.approve(
          this.currentWithdrawal.id
        );
        this.$toast.open({
          message: response.msg,
          position: "top-right",
          type: "success",
          duration: 2500,
          pauseOnHover: true,
        });

        setTimeout(() => {
          location.reload();
        }, 2500);
      } catch (e) {
        this.$toast.open({
          message: e.msg,
          position: "top-right",
          type: "error",
          duration: 2500,
          pauseOnHover: true,
        });
      }
      this.redeemLoading = false;
      this.loading = false;
    },

    async Cancel() {
      this.cancelLoading = true;
      this.loading = true;
      try {
        const response = await WithdrawalService.cancel(
          this.currentWithdrawal.id
        );
        this.$toast.open({
          message: response.msg,
          position: "top-right",
          type: "success",
          duration: 2500,
          pauseOnHover: true,
        });
        setTimeout(() => {
          location.reload();
        }, 2500);
      } catch (e) {
        this.$toast.open({
          message: e.msg,
          position: "top-right",
          type: "error",
          duration: 2500,
          pauseOnHover: true,
        });
      }
      this.cancelLoading = false;
      this.loading = false;
    },
  },
  props: ["currentWithdrawal"],
};
</script>
