<template>
  <div>
    <p class="mt- h4 bold1">All Withdrawal Request</p>
    <div class="row justify-content-between my-4">
      <div class="col-md-4">
        <div class="d-flex align-items-cener mb-3">
          <div class="input-group-append">
            <span class="bgBg py- px-3 border border-dark border-right-0">
              <i class="fa fa-search text-white lead pt-2"></i>
            </span>
          </div>
          <input
            class="border-left-0"
            placeholder="Search"
            v-model="search"
            type="search"
            prepend
          />
        </div>
      </div>
    </div>
    <div data-aos="fade-in" data-aos-delay="100">
      <v-data-table
        class="border"
        :items-per-page="6"
        :headers="headers"
        :items="items"
        :search="search"
        item-key="name"
      >
        <template v-slot:body="{ items, headers }">
          <tbody name="list" is="transition-group" v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="index" class="item-row">
              <td>
                <span class="p d-block mb-0">
                  {{ item.createdAt | formartDate }}</span
                >
              </td>

              <td>&#8358;{{ item.amount | formartNumber }}</td>
              <td>{{ item.user }}</td>
              <td class="font-weight-bold">
                <span class="text-success" v-if="item.status == 'COMPLETED'">
                  {{ item.status }}
                </span>
                <span class="text-danger" v-if="item.status == 'CANCELLED'">
                  {{ item.status }}
                </span>
                <span class="text-warning" v-if="item.status == 'PENDING'">
                  {{ item.status }}
                </span>
              </td>
              <td>
                <div class="d-flex align-items-">
                  <button
                    @click="viewItem(item)"
                    type="button"
                    data-toggle="modal"
                    data-target="#withdrawalModalCenter"
                    class="btnd btn-success border-0 text-light p px-2"
                  >
                    <span class="p">
                      action
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="headers.length" style="text-align: center">
                No History Yet!
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <ActionModal v-if="showModal" :currentWithdrawal="currentWithdrawal" />
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
import WithdrawalService from "@/services/withdrawal.service";
import ActionModal from "@/components/withdrawal/ActionModal.vue";
export default {
  name: "Withdrawal",
  components: {
    ActionModal,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Date",
          align: "start",
          value: "name",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "User",
          value: "user",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
        },
      ],
      items: [],
      currentWithdrawal: {},
      showModal: false,
    };
  },
  methods: {
    async fetchTxn() {
      try {
        const response = await WithdrawalService.fetchAll();
        this.items = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async viewItem(val) {
      this.currentWithdrawal = val;
      this.showModal = true;
    },
  },
  async created() {
    this.fetchTxn();
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>

<style scoped>
ion-icon {
  font-size: 1.19rem;
  margin: 0px;
  padding: 0px;
}

.v-data-footer {
  font-size: 0.9rem !important;
}
</style>
