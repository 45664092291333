/* eslint-disable no-async-promise-executor */
import axios from "axios";

export default {
    fetchAll() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/withdraw-requests");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    approve(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.put(
                    `/admin/approve-withdrawal?withdrawRequestId=${id}`
                );
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    cancel(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.put(
                    `/admin/cancel-withdrawal?withdrawRequestId=${id}`
                );
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
};